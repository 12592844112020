:root {
  --primary: #2e384f;
  --secondary: #404b69;
  --third: white;
  --fourth: #b72927;
}

html,
body {
  margin: 0 auto;
  overflow-x: hidden;
  font-family: "Poppins", sans-serif;
  min-width: 320px;
}

.top-margin {
  z-index: 50;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
  height: 3rem;
  width: 100%;
  min-width: 320px;
  background-color: white;
  transition: all 0.3s ease-in-out;
}

.links {
  position: relative;
  display: flex;
  justify-items: center;
  align-items: center;
  color: #2e384f;
  transition: all 0.6s ease-in-out;
}

.links ul {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  list-style: none;
}

.links h5 {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.links a {
  display: flex;
  justify-content: center;
  align-items: center;
}


.links button {
  padding: 0;
}

.links i {
  padding-left: 1rem;
  font-size: 1.5rem;
}

.links i:hover {
  animation: heartBeat;
  animation-duration: 1s;
  color: var(--fourth);
}

.links a {
  color: #2e384f;
}

.links a:visited {
  color: #2e384f;
}

.top-margin button {
  border: none;
  background: none;
  color: #2e384f;
}

.top-margin button:focus {
  outline: 0;
}

.bottom-margin {
  z-index: 30;
  position: fixed;
  margin: 0;
  bottom: 0;
  height: 3rem;
  width: calc(100vw - 3rem);
  background-color: white;
  transition: all 0.3s ease-in-out;
}

.scroll-to-top-button {
  z-index: 35;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 3rem;
  right: 3rem;
  height: 3rem;
  width: 3rem;
  background-color: var(--fourth);
  border: none;
  border-radius: 50px;
  box-shadow: 10px 10px 24px -12px rgba(0, 0, 0, 0.75);
  color: white;
  animation: buttonPulse 1.2s infinite;
  transition: all 0.3s ease-in-out;
}

.scroll-to-top-button:hover {
  background-color: var(--fourth);
  animation: heartBeat;
  animation-duration: 1s;
}

.scroll-to-top-button:focus {
  outline: 0;
}
@keyframes buttonPulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.title-home {
  z-index: 40;
  position: absolute;
  height: 100vh;
  width: 100vw;
  min-width: 320px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10rem;
  font-weight: bold;
  transition: all 0.5s ease-in-out;
}

.title-home p {
  z-index: 25;
  color: var(--third);
  transition: all 0.5s ease-in-out;
}

.title-home span {
  color: var(--fourth);
  transition: all 0.5s ease-in-out;
}

.title-about {
  z-index: 25;
  position: absolute;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 0;
  transition: all 0.3s ease-in-out;
}

.title-about span {
  color: #2e384f;
  transition: all 0.5s ease-in-out;
}

.title-about p {
  z-index: 25;
  color: black;
  transition: all 0.5s ease-in-out;
}

/* MENU COMPONENT */
.Menu {
  position: relative;
  height: 100%;
  min-width: 320px;
  transition: all 0.3s ease-in-out;
}

.Menu-panel {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 3rem;
  left: 0;
  height: 100vh;
  width: 50vw;
  max-width: 600px !important;
  background-color: white;
  box-shadow: 7px 2px 23px -3px rgba(0,0,0,0.4)
  ;
  transition: all 0.3s ease-in-out;
}

.Menu-panel ul {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50%;
  width: 50%;
  margin: 5rem;
  padding: 0;
  list-style: none;
  transition: all 0.3s ease-in-out;
}

.Menu-panel li {
  padding: 1rem;
}

.Menu-list-item {
  margin: 5rem;
  color: black;
  font-weight: 200;
  font-size: 1rem;
}

.Menu-list-item:hover {
  text-decoration: none;
  color: var(--fourth);
  font-weight: bold;
}

.Menu-button {
  z-index: 35;
  position: fixed;
  top: 50%;
  left: 1.5rem;
  height: 3rem;
  width: 3rem;
  border: none;
  border-radius: 50%;
  background-color: white;
  box-shadow: 10px 10px 24px -12px rgba(0, 0, 0, 0.75);
  color: var(--primary);
  transition: all 0.3s ease-in-out;
}

.Menu-button-selected {
  z-index: 35;
  position: fixed;
  top: 50%;
  left: 1.5rem;
  height: 3rem;
  width: 3rem;
  border: none;
  border-radius: 50%;
  background-color: var(--fourth);
  box-shadow: 10px 10px 24px -12px rgba(0, 0, 0, 0.75);
  color: white;
  font-size: 1rem;
  transition: all 0.3s ease-in-out;
}

.Menu-button:hover {
  cursor: pointer;
  background-color: var(--secondary);
  transform: scale(1.1);
  color: white;
  animation: heartBeat;
  animation-duration: 1s;
  transition: all 0.3s ease-in-out;
}

.Menu-button-selected:hover {
  cursor: pointer;
  background-color: #2e384f;
  transform: scale(1.1);
  color: white;
  animation: heartBeat;
  animation-duration: 1s;
  transition: all 0.3s ease-in-out;
}

.Menu-button:focus {
  outline-style: none;
}

.Menu-button-selected:focus {
  outline-style: none;
}

.Menu-button p {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  animation: pulse-text 2s infinite;
}

.Menu-button-selected p {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  animation: pulse-text 2s infinite;
}

.Menu-button-border {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border: 1px solid #2e384f;
  border-radius: 50%;
  opacity: 0.1;
  animation: pulse-border 2s infinite;
}

@keyframes pulse-border {
  0% {
    transform: scale(1);
    opacity: 0.1;
  }
  50% {
    transform: scale(1.1);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0.1;
  }
}

@keyframes pulse-text {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.hide-left {
  z-index: 20;
  opacity: 0;
  left: -100rem !important;
  transition: opacity 1s ease-in-out;
}

/* LOADING COMPONENT */
.Loading {
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: white;
}
.Loading-bar {
  position: relative;
  height: 2px;
  width: 500px;
  margin: 0 auto;
  background: black;
  margin-top: 150px;
}

.Loading-circle {
  position: absolute;
  top: -30px;
  margin-left: -30px;
  height: 60px;
  width: 60px;
  left: 0;
  background: black;
  border-radius: 30%;
  animation: move 3.2s infinite;
}

.Loading p {
  position: absolute;
  top: -20px;
  right: -85px;
  text-transform: uppercase;
  color: white;
  font-weight: bold;
}

@keyframes move {
  0% {
    left: 0;
  }
  50% {
    left: 100%;
    -webkit-transform: rotate(450deg);
    width: 150px;
    height: 150px;
  }
  75% {
    left: 100%;
    -webkit-transform: rotate(450deg);
    width: 150px;
    height: 150px;
  }
  100% {
    right: 100%;
  }
}

/* HOME COMPONENT */
.Home {
  margin: 0;
  padding: 3rem;
  height: calc(100vh - 6rem);
  width: 100%;
  min-width: 320px;
  display: flex;
  flex-direction: row;
  transition: all 0.5s ease-in-out;
}

.Home-left-container {
  position: relative;
  left: 0;
  width: 50%;
  background-color: var(--primary);
}

.Home-right-container {
  z-index: 0;
  position: relative;
  right: 0;
  width: 50%;
  background-color: var(--secondary);
  color: var(--third);
}

.Home-left-container-menu {
  z-index: 40;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  padding: 0;
  left: 0;
  bottom: 3rem;
  width: 200%;
  list-style-type: none;
}

.Home-left-container-menu-list-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.Home-left-container-menu-list {
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
  text-align: center;
  transition: all 1s ease-in-out;
}

.Home-left-container-menu-list-item {
  margin: 0 2rem;
  color: var(--third);
  font-size: 1.2rem;
  font-weight: 700;
}

.Home-left-container-menu-list-item:hover {
  text-decoration: none;
  color: var(--fourth);
  font-weight: 700;
  animation: heartBeat;
  animation-duration: 1s;
  transition: all 0.3s ease-in-out;
}

.Home-left-container-menu-list-arrow {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.Home-left-container-menu-list-arrow i {
  font-size: 2.5rem;
  animation: animateDownArrow 1.5s infinite;
  color: var(--fourth);
}

@keyframes animateDownArrow {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-5px);
  }
  60% {
    transform: translateY(-2.5px);
  }
}

.Home-left-container-heading {
  z-index: 25;
  padding: 3rem;
  align-items: center;
  color: white;
}

.Home-left-container-heading h1 {
  font-weight: 700;
  font-size: 2rem;
}

.Home-left-container-heading h6 {
  opacity: 0.8;
  font-weight: 100;
}

.hide {
  z-index: 20;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.Home-right-container-description {
  position: relative;
  padding: 0 1rem;
  /* margin: 8rem 2rem; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
  height: 100%;
  transition: all 0.5s ease-in-out;
}

.Home-right-container-description h5 {
  margin-top: 12rem;
}

/* ABOUT COMPONENT */
.About {
  position: relative;
  display: flex;
  justify-content: center;
  margin: 0;
  min-width: 320px;
  color: black;
  background-color: #f9f9f9;
  border-bottom: 2px solid rgb(221, 221, 221);
}

.About-text-container {
  padding: 20rem;
  max-width: 1920px;
}

.About h1 {
  font-weight: bold;
  color: var(--fourth);
}

.About h1 span {
  border-bottom: 4px solid var(--primary);
}

.About h6 {
  margin-left: 4rem;
  line-height: 1.5;
}

.About-text-container-image {
  padding-top: 2rem;
  display: flex;
  justify-content: center;
}

.About img {
  height: 15rem;
  width: 15rem;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid white;
}

/* PROJECTS COMPONENT */
.Projects {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  height: 80%;
  min-width: 320px;
  color: black;
  transition: all 0.3s ease-in-out;
}

.Projects-text-container {
  margin: 0 auto;
  padding: 10rem 20rem 5rem 20rem;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1920px;
}

.Projects-header h1 {
  color: var(--fourth);
  font-weight: 700;
}

.Projects-header h1 span {
  border-bottom: 4px solid var(--primary);
}

.Projects-header h6 {
  padding-top: 2rem;
  padding-left: 5rem;
  line-height: 2;
  color: black;
}

.Projects-project-description {
  padding: 0 3rem;
  width: 50%;
}

.Projects-project-description h4 {
  text-transform: uppercase;
}

.Projects-project-description h4 span {
  z-index: 0;
  color: #2e384f;
  font-size: 3rem;
}

.Projects-image-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto 10rem auto;
  height: 100%;
  width: 90%;
  max-width: 1920px;
  /* border: 1px solid #F9F9F9;
    box-shadow: 0px 0px 20px -16px rgba(0,0,0,0.75);    */
}

.Projects-image-card {
  margin: 0;
  padding: 0.5rem 0;
  height: 100%;
  width: 33.3333%;
}

/* .Projects-image-card-image-overlay {
    position: absolute;
    display: flex;
    align-items: center;
    text-align: center;
    height: 100%;
    width: 100%;
    background-color: black;
}

.Projects-image-card-image-overlay h6 {
    color: white;
    font-size: 1.5rem;
    font-weight: 700;
    width: 100%;
} */

.Projects-image-card-image {
  position: relative;
  height: 100%;
  width: 100%;
  border-right: 1px solid #f9f9f9;
  border-bottom: 1px solid #f9f9f9;
}

.Projects-image-card-image img {
  position: relative;
  height: 100%;
  width: 100%;
  opacity: 0.8;
  transition: all 0.5s ease-in-out;
}

.Projects-image-card-detail {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  color: white;
  background-color: black;
  border: none;
  opacity: 0;
  font-size: 2rem;
  transition: all 0.5s ease-in-out;
}

.Projects-image-card-detail:hover {
  opacity: 0.7;
}

.Projects-image-card-button {
  flex-direction: row;
  width: 100%;
}

.Projects-image-card-button button {
  position: relative;
  width: 50%;
}

/* PROJECT COMPONENT */
.modal-backdrop {
  background-color: black;
  opacity: 0.8 !important;
  min-width: 320px;
}
.modal-content {
  padding: 1rem;
  box-shadow: 10px 10px 29px 0px rgba(0, 0, 0, 0.75);
}
.Project-body h6 {
  font-weight: 200;
  margin-bottom: 2rem;
}

.Project-body p {
  font-weight: 200;
}

.Project-body ul {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  list-style: none;
}

.Project-body li {
  margin: 0 0.2rem 0.2rem 0;
  padding: 3px;
  background-color: #2e384f;
  border: 1px solid rgb(109, 206, 255);
  color: white;
  border-radius: 50px;
  font-size: 0.8rem;
  font-weight: 200;
}

.Project-body img {
  margin: 2rem 0;
  width: 100%;
  box-shadow: 0 10px 30px 0px rgba(51, 51, 51, 0.5);
}

.Project-demo-button {
  background-color: #dab333;
  border: none;
  transition: all 0.3s ease-in-out;
}

.Project-demo-button:hover {
  background-color: #ffd33d;
}

.Project-repo-button {
  background-color: #28a745;
  border: none;
  transition: all 0.3s ease-in-out;
}

.Project-repo-button:hover {
  background-color: rgb(44, 196, 79);
}

/* CONTACT COMPONENT */
.Contact {
  z-index: 30;
  position: relative;
  left: -3rem;
  bottom: -3rem;
  background-color: #2e384f;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  min-width: 320px;
}

.Contact-container {
  margin: 6rem;
  width: 70%;
  max-width: 1920px;
  background-color: white;
  border-radius: 10px;
}

.Contact-container-content {
  margin: 2rem 10rem;
}

.Contact-container-content h1 {
  margin-bottom: 3rem;
}

.Contact-header-title {
  border-bottom: 4px solid var(--primary);
}

.Contact-information {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.Contact-information-detail {
  margin: 2rem 2rem 6rem 2rem;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Contact-information-detail i {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  height: 5rem;
  width: 5rem;
  border: 2px solid #2e384f;
  border-radius: 50%;
  color: #2e384f;
  font-size: 2rem;
}

.Contact-information-click-to-copy {
  cursor: pointer;
}

.Contact-information-click-to-copy:hover
  ~ .Contact-information-click-to-copy-tooltip {
  visibility: visible;
}

.Contact-information-click-to-copy-tooltip:hover {
  visibility: visible;
  cursor: pointer;
}

.Contact-information-click-to-copy-tooltip {
  z-index: 1;
  visibility: hidden;
  position: absolute;
  padding: 5px 0;
  width: 120px;
  background-color: #2e384f;
  border-radius: 6px;
  color: #fff;
  text-align: center;
  opacity: 0.8;
}

.Contact-information-click-to-copy-tooltip-copied {
  z-index: 10;
  position: absolute;
  padding: 5px 0;
  width: 120px;
  background-color: #2e384f;
  border-radius: 6px;
  color: #fff;
  text-align: center;
  opacity: 0.8;
}

.Contact-form-title {
  display: block;
  width: 100%;
  font-weight: 700;
  font-size: 39px;
  color: var(--fourth);
  line-height: 1.2;
  text-align: left;
  padding-bottom: 44px;
}

.Contact-form-section-1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Contact-form-section-1-input {
  display: flex;
  flex-direction: column;
  margin: 0 1rem 0 0;
  width: 100%;
}

.Contact-form-input-container {
  margin: 0 2rem 0 0;
  width: 100%;
  position: relative;
  border-bottom: 2px solid #d9d9d9;
  padding-bottom: 13px;
  margin-bottom: 65px;
  font-weight: 400;
}

.Contact-form-label {
  padding-left: 5px;
  font-size: 0.9rem;
  color: #999999;
  line-height: 1.5;
}

.Contact-form-input {
  display: block;
  margin-top: 2rem;
  padding: 0 1rem;
  width: 100%;
  background: transparent;
  border: none;
  color: #555555;
  font-size: 1rem;
  line-height: 1.2;
}

.Contact-form-input textarea {
  display: block;
  margin-top: 2rem !important;
  padding: 0 1rem;
  width: 100%;
  background: transparent;
  border: none;
  color: #555555;
  font-size: 1rem;
  line-height: 1.2;
}
.Contact-form-input:hover + .Contact-form-input-focus:before {
  width: 100%;
}

.Contact-form-input:focus {
  outline: none;
}

.Contact-form-input:focus::placeholder {
  color: transparent;
}

.Contact-form-input:focus + .Contact-form-input-focus:before {
  width: 100%;
}

.Contact-form-input-focus {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

.Contact-form-input-focus:before {
  content: "";
  display: block;
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 2px;
  transition: all 0.5s ease-in-out;
  background: #2e384f;
}

.container-contact100-form-btn {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: -25px;
}

.contact100-form-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  min-width: 160px;
  height: 50px;
  background-color: #2e384f;
  border: none;
  border-radius: 25px;
  font-size: 16px;
  color: #fff;
  line-height: 1.2;
  transition: all 0.4s;
  box-shadow: 0 10px 30px 0px rgb(84, 163, 202);
}

.contact100-form-btn:focus {
  outline: none;
}

.contact100-form-btn i {
  transition: all 0.4s;
}

.contact100-form-btn:hover {
  background-color: #333333;
  box-shadow: 0 10px 30px 0px rgba(51, 51, 51, 0.5);
}

.contact100-form-btn:hover i {
  transform: translateX(10px);
}

.animate-sending:after {
  content: " .";
  animation: dots 1s steps(5, end) infinite;
}

@keyframes dots {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: white;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 white;
  }
}

.form-error-message {
  position: relative;
  top: -3rem;
  font-size: 0.8rem;
  color: red;
}

.alert {
  z-index: 55;
  position: fixed;
  margin-left: -150px;
  left: 50%;
  width: 300px;
  display: flex;
  align-items: center;
  transition: all 0.3s ease-in-out;
  font-size: 0.9rem;
}

.alert p {
  margin: 0;
}

/* RESPONSIVE SIZE */
@media all and (max-width: 1280px) {
  .title-home {
    font-size: 8rem;
  }

  .Home-left-container-heading h1 {
    font-size: 1.5rem;
  }

  .Home-left-container-heading h6 {
    font-size: 0.9rem;
  }

  .Home-right-container-description h5 {
    font-size: 1rem;
  }

  .About-text-container {
    padding: 20rem 10rem;
  }

  .About h6 {
    margin-left: 4rem;
    line-height: 1.5;
    font-size: 0.9rem;
  }

  .About img {
    height: 15rem;
    width: 15rem;
  }

  .Projects-text-container {
    padding: 8rem 16rem 2.5rem 16rem;
  }

  .Projects-image-container {
    width: 90%;
  }

  .Contact-container {
    width: 90%;
  }

  .Contact-container-content {
    margin: 2rem 2rem;
  }

  .Contact-form-label {
    font-size: 0.8rem;
  }

  .Contact-form-input {
    margin-top: 1.5rem;
    font-size: 0.9rem;
  }
}

@media all and (max-width: 736px) {
  .links i {
    font-size: 1.5rem;
  }

  .title-home {
    font-size: 6rem;
  }

  .Menu-panel {
    top: 2rem;
  }

  .Menu-button {
    left: 0.5rem;
  }

  .Menu-button-selected {
    left: 0.5rem;
  }

  .Home {
    padding: 2rem;
    height: calc(100vh - 4rem);
  }

  .Home-left-container-menu {
    bottom: 3rem;
  }

  .Home-right-container-description {
    position: relative;
    width: calc(100vw - 6rem);
    left: calc(-50vw + 3rem);
  }

  .About-text-container {
    padding: 15rem 5rem;
  }

  .About h6 {
    margin-left: 2rem;
    line-height: 1.5;
    font-size: 0.9rem;
  }

  .About-text-container-image {
    padding-top: 0;
  }

  .About img {
    height: 12rem;
    width: 12rem;
  }

  .Projects-image-container {
    /* margin: 2rem; */
    width: 90%;
  }

  .Projects-text-container {
    padding: 8rem 12rem 2.5rem 12rem;
  }

  .Projects-image-card {
    margin: 0;
    padding: 1rem 0 4rem 0;
    width: 100%;
  }

  .Contact {
    left: -2rem;
    bottom: -2rem;
  }

  .Contact-information-detail {
    margin: 1rem 1rem 2rem 1rem;
  }

  .Contact-information-detail i {
    height: 4rem;
    width: 4rem;
    font-size: 1.5rem;
  }

  .Contact-information-detail p {
    font-size: 0.5rem;
  }

  .Contact-form-section-1-input {
    display: flex;
    flex-direction: column;
  }

  .Contact-form-input::placeholder {
    font-size: 0.8rem;
  }
}

@media all and (max-width: 576px) {
  .top-margin {
    padding: 0.6rem 1rem;
    height: 3rem !important;
    width: 100vw;
  }

  .scroll-to-top-button {
    right: 1rem;
    height: 2rem;
    width: 2rem;
  }

  .links i {
    font-size: 1.6rem;
  }

  .bottom-margin {
    height: 0 !important;
  }

  .title-home {
    font-size: 5rem;
  }

  .title-home p {
    margin-bottom: 3rem;
  }

  .Menu {
    margin: 0 !important;
    width: calc(100vw - 3rem);
  }

  .Menu-button {
    z-index: 55;
    top: 0;
    left: 0;
    border-radius: 0;
    box-shadow: none;
  }

  .Menu-button-selected {
    z-index: 55;
    top: 0;
    left: 0;
    border-radius: 0;
    box-shadow: none;
  }

  .Menu-button p {
    animation: none;
  }

  .Menu-button-selected p {
    animation: none;
  }

  .Menu-button-border {
    display: none;
  }

  .Home {
    margin: 0;
    padding: 0 !important;
    height: calc(100vh - 3rem);
    width: 100vw;
  }

  .Home-left-container-heading {
    margin-top: 2rem;
    padding-inline: 0.5rem;
  }

  .Home-left-container-heading h1 {
    font-size: 22px;
  }

  .Home-left-container-heading  h5 {
    font-size: 16px;
  }

  .Home-left-container-menu {
    width: 100vw;
  }

  .Home-left-container-menu {
    bottom: 8rem;
  }

  .Home-right-container-description {
    width: 100vw;
    left: -50vw;
  }

  .Home-right-container-description h5 {
    font-size: 14px;
    line-height: 20px;
  }
  .About {
    width: 100vw;
  }

  .About-text-container {
    padding: 15rem 3rem;
  }

  .About h1 {
    font-size: 2rem;
  }

  .Projects {
    width: 100vw;
  }

  .Projects-text-container {
    padding: 4rem 3rem;
  }

  .Projects h1 {
    font-size: 2rem;
  }

  .Projects-image-card {
    padding: 0.5rem 0 3rem 0.5rem;
  }

  .Projects-image-container {
    margin: 0;
    width: 100%;
  }

  .Contact {
    margin-top: 4rem;
    left: 0;
    bottom: 0;
  }

  .Contact-container {
    margin: 3rem;
    width: 95%;
  }

  .Contact-form-title {
    font-size: 2rem;
  }

  .Contact-form-input::placeholder {
    font-size: 0.5rem;
  }

  .alert-sent {
    margin-left: -125px;
    left: 50%;
    width: 250px;
    display: flex;
    align-items: center;
    font-size: 0.7rem;
  }
}
